import React from 'react';
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {MdSearch} from "react-icons/md";
import {FaMicrophone, FaSearch} from "react-icons/fa";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import FastX from "../assets/images/fast-x-poster.jpg"
import Img65 from "../assets/images/65-large-poster.jpg"
import WatchlistItem from "./WatchlistItem";
import MovieListItem from "./MovieListItem";

const data = [
    {
        key: 1,
        img: FastX,
    },
    {
        key: 2,
        img: Img65,
    }
]

const _renderItem = ({img}) => {
    return (
        <div className='carousel-image'
             style={{backgroundImage: `url(${img})`,
            backgroundSize: 'contain'}}
        >
            {/*<img src={img} className="img img-fluid opacity-75 w-100"*/}
            {/*     style={{maxWidth: '100%', height: 'auto'}} />*/}
            <div style={{position: 'absolute', bottom: 130, left: 70}}>
                <h3 className="text-white d-block">Venom 2: Let there be Carnage</h3>
            </div>
            <div style={{position: 'absolute', bottom: 100, left: 70}}>
                <span className="text-white d-inline-block pe-4">1 Hours, 37 Minutes</span>
                <span className="text-white d-inline-block">2021</span>
            </div>
            <div className="w-100 d-flex" style={{position: 'absolute', bottom: 50, left: 50}}>
                <div className="d-inline-block px-3">
                    <button className="btn btn-primary rounded-3" >Watch Now</button>
                </div>
                <div className="d-inline-block">
                    <button className="btn btn-outline-light rounded-3" >
                        <span style={{fontWeight: '900'}}>Add to Watchlist</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

const TopMenu = () => {
    return (
        <div className="top-navigation">
            <Row>
                <div className="col-8">
                    <Carousel showThumbs={false} autoPlay={true}>
                        <_renderItem img={FastX} />
                        <_renderItem img={Img65} />
                    </Carousel>
                </div>
                <div className="col-4" style={{textAlign: 'left'}}>
                    <p style={{alignSelf: 'flex-start', fontWeight: '700'}}>Your Watchlist</p>
                    <WatchlistItem />
                </div>
            </Row>
            <Row className="mt-4">
                <Col>
                    <MovieListItem />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <MovieListItem />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <MovieListItem />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <MovieListItem />
                </Col>
            </Row>
        </div>
    );
};

export default TopMenu;
