import React from 'react';
import {Col, Row} from "reactstrap";
import Guardians from "../assets/images/guardians-3.jpg"
import HungerGames from "../assets/images/hunger-games-ballad-poster.jpg"
import JohnWick from "../assets/images/John-Wick-Chapter-4.jpg"
import TheFlash from "../assets/images/the-flash.jpg"

const MovieListItem = () => {
    return (
        <>
            <Row>
                <Col style={{textAlign: 'left'}}>
                    <p style={{fontWeight: '700', alignSelf: 'flex-start'}}>Featured Movies</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <img style={{width: '100%', height: 200}} src={Guardians} alt="movie-image"/>
                </Col>
                <Col>
                    <img style={{width: '100%', height: 200}} src={HungerGames} alt="movie-image"/>
                </Col>
                <Col>
                    <img style={{width: '100%', height: 200}} src={JohnWick} alt="movie-image"/>
                </Col>
                <Col>
                    <img style={{width: '100%', height: 200}} src={TheFlash} alt="movie-image"/>
                </Col>
            </Row>
        </>
    );
};

export default MovieListItem;
