import React from 'react';
import {FaHome, FaSearch} from "react-icons/fa";
import {
    MdCompassCalibration,
    MdHome,
    MdLocalMovies, MdLogout,
    MdMap,
    MdMovie,
    MdNavigation,
    MdOutlineLocalMovies, MdSearch, MdSettings, MdStar, MdVideocam, MdVideoFile
} from "react-icons/md";
import Logo from "../assets/images/logo-light.png"

const SideMenu = ({style, icon, text}) => {
    return (
        <div className="side-menu py-4">
            <img src={Logo} alt="logo" className="app-logo"/>
            <div className="side-menu-item">
                <MdSearch size={30} className="d-inline-block pe-2" /> <span className="d-inline-block">Search</span>
            </div>
            <div className="side-menu-item">
                <MdHome size={30} className="d-inline-block pe-2" /> <span className="d-inline-block">Home</span>
            </div>
            <div className="side-menu-item">
                <MdMap size={30} className="d-inline-block pe-2" /> <span className="d-inline-block">Channels</span>
            </div>
            <div className="side-menu-item">
                <MdMovie size={30} className="d-inline-block pe-2" /> <span className="d-inline-block">Movies</span>
            </div>
            <div className="side-menu-item">
                <MdVideocam size={30} className="d-inline-block pe-2" /> <span className="d-inline-block">TV Shows</span>
            </div>
            <div className="side-menu-item">
                <MdStar size={30} className="d-inline-block pe-2" /> <span className="d-inline-block">Favourite</span>
            </div>
            <div className="bottom-items">
                <div className="side-menu-item">
                    <MdSettings size={30} className="d-inline-block pe-2" /> <span className="d-inline-block">Settings</span>
                </div>
                <div className="side-menu-item">
                    <MdLogout size={30} className="d-inline-block pe-2" /> <span className="d-inline-block">Logout</span>
                </div>
            </div>
        </div>
    );
};

export default SideMenu;
