import React, {useEffect} from 'react';
import SideMenu from "../components/SideMenu";
import TopMenu from "../components/TopMenu";
import {isMobile} from "react-device-detect";
import Logo from "../assets/images/logo-light.png"

const Home = (props) => {
    // useEffect(() => {
    //     alert(isMobile)
    // },[])

    return (
        <>
            {
                isMobile ?
                    <div className="container-fluid mt-auto">
                        <div className="mt-5">
                            <img src={Logo} alt="logo"/>
                            <h1>Please download our mobile app from the App Store</h1>
                        </div>

                    </div>:
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-2 mx-0 text-center">
                                <SideMenu />
                            </div>
                            <div className="col-10 my-4">
                                <TopMenu />
                            </div>
                        </div>
                    </div>
            }
        </>
    );
};

export default Home;
