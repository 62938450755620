import React from 'react';
import FastX from "../assets/images/fast-x-poster-1-400x600.jpg"
import {Col, Row} from "reactstrap";
import {FaStar} from "react-icons/fa";

const WatchlistItem = () => {
    return (
        <>
            <div className="mb-2">
                <Row>
                    <Col xs={4}>
                        <img className="img img-fluid watchlist-item-image d-inline me-3" src={FastX} alt="movie image"/>
                    </Col>
                    <Col>
                        <p className="mb-1" style={{fontWeight: '600'}}>Fantastic Four</p>
                        <p className="mb-1">Action, Adventure</p>
                        <p className="mb-1 justify-content-center">
                            <FaStar color='gold' size={17} /> 5.0
                        </p>
                        <p className="mb-1">2 Hours, 31 Minutes | 2021</p>
                    </Col>
                </Row>
            </div>
            <div>
                <Row>
                    <Col xs={4}>
                        <img className="img img-fluid watchlist-item-image d-inline me-3" src={FastX} alt="movie image"/>
                    </Col>
                    <Col>
                        <p className="mb-1" style={{fontWeight: '600'}}>Fantastic Four</p>
                        <p className="mb-1">Action, Adventure</p>
                        <p className="mb-1 justify-content-center">
                            <FaStar color='gold' size={17} /> 5.0
                        </p>
                        <p className="mb-1">2 Hours, 31 Minutes | 2021</p>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default WatchlistItem;
